var dashboardSummary = {

    // Generate dashboard summary based on selected options
    generate: function () {
        
        $('#dashboard-summary-organisations').html('');
        var orgs = $('#organisations-modal-tab-selected .item-list[style*="block"]');
        $.each(orgs, function () {
            $('#dashboard-summary-organisations').append($(this).html());
        });
        
        if($('#dashboard-summary-organisations').html() === '') {
            $('#dashboard-summary-organisations').html('National');
        }

        if($('.mrp-upperMonth').length>0){
            var timePeriod = $('.mrp-lowerMonth').html() + ' to ' + $('.mrp-upperMonth').html();
            $('#dashboard-summary-time').html(timePeriod);
        }
        if($('.rx_message:visible').length > 0){
            $('#dashboard-summary-time').append('<br><div class="m-b-md">'+$('.rx_message:visible').html()+'</div>');
        }

        if($('.mrp-upperMonth').length <= 0 && $('.rx_message:visible').length <= 0){
            $('.exportSummaryTime').html('');
        }

        $('#dashboard-summary-codes').html('');
        var codes = $('#codes-modal-tab-selected .item-list[style*="block"]');

        $.each(codes, function () {
            $('#dashboard-summary-codes').append($(this).html());
        });
        
        var codeDivs = $('#dashboard-summary-codes div');
        $.each(codeDivs, function () {
            if ($(this).attr('data-selection')) {
                var codeHtml = $(this).html();
                var dataSelection = dashboardSummary.toTitleCase($(this).attr('data-selection'));
                codeHtml = '<i class="hidden">' + dataSelection + ': </i>' + codeHtml;
                $(this).html(codeHtml);
            }
        });
    },

    // Obtain the body section of the summary (i.e. exclude modal HTML)
    getBody: function () {
        this.generate();
        return $('#dashboard-summary-body').html();
    },
    
    // ucwords equivalent
    toTitleCase: function(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

};

$(document).ready(function() {
    dashboardSummary.generate();    
});
